import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from '../../UserContext';

const PrivateRoute = ({ children }) => {
  const { isAdmin } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    if (isAdmin !== null) setLoading(false);
  }, [isAdmin]);

  if (loading) {
    return <div className="loader" />
  }

  return isAdmin ? children : <Navigate to="/LoginAdmin" />;
};

export default PrivateRoute;
