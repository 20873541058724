import React, { useState, useRef } from "react";

const InputExpand = ({ text, setText, onEnterPress, ...args }) => {
  const textAreaRef = useRef(null);
  const [enterPressed, setEnterPressed] = useState(false);


  const handleChange = (event) => {
    setText(event.target.value);
    adjustHeight();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (!enterPressed) {
        setEnterPressed(true);
        onEnterPress?.();
      }
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      setEnterPressed(false);
    }
  };

  const adjustHeight = () => {
    textAreaRef.current.style.height = "auto";
    textAreaRef.current.style.height = "calc( " + textAreaRef.current.scrollHeight + "px)";
  };

  return (
    <textarea
      ref={textAreaRef}
      value={text}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      rows={1}
      style={{
        resize: "none",
        overflow: "hidden",
        boxSizing: "border-box",
        lineHeight: "1.5",
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
      }}
      {...args}
    />
  );
};

export default InputExpand;
