import { useEffect, useContext, useState } from "react";
import CircularProgressBarPasos from "../Graficos/CircularProgressBarPasos";
import { useTranslation } from "react-i18next";
import Pasos_Icono from "../../Images/Pasos_Icono.png";
import Pasos_Icono_SinRelleno from "../../Images/Pasos_Icono_SinRelleno.png";
import Distancia_Gris from "../../Images/Distancia_Gris.png";
import axios from "axios";
import UserContext from "../../UserContext";
import "./ActividadCarta.css"
import { style } from "d3";


function ActividadCarta({ }) {
  const email = useContext(UserContext);
  const [pasos_totales, setPasos_totales] = useState(null);
  const [distancia, setDistancia] = useState(null);
  const [objetivo, setObjetivo] = useState(null);
  const [t] = useTranslation("global");
  const [fecha, setFecha] = useState(null);

  useEffect(() => {
    // Verificar si el correo electrónico no es nulo antes de llamar a la función
    if (email.email) {
      consulta_objetivo();
      consulta_distancia();
    }
  }, [email]);
  useEffect(() => {
    // Verificar si el correo electrónico no es nulo antes de llamar a la función
    if (email.email && fecha) {

      consulta_pasos();
    }
  }, [email, fecha]);

  const transformarFecha = (fecha) => {
    // Dividir la cadena en día, mes y año
    const partes = fecha.split('-');

    // Crear un objeto Date con las partes extraídas
    const fechaObj = new Date(`${partes[2]}-${partes[1]}-${partes[0]}`);

    // Obtener el año, mes y día en formato deseado
    const año = fechaObj.getFullYear();
    const mes = (fechaObj.getMonth() + 1).toString().padStart(2, '0');
    const día = fechaObj.getDate().toString().padStart(2, '0');

    // Formatear la fecha como "yyyy-mm-dd"
    const fechaFormateada = `${año}-${mes}-${día}`;

    return fechaFormateada;
  };
  const formatearNumeroConPuntoDeMiles = (numero) => {
    if (numero === null) {
      return 0;
    } else{
    return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");}
  }
  const consulta_pasos = async () => {
    const currentDate = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(currentDate.getDate() - 365);
    const formattedCurrentDate = transformarFecha(fecha)
    const formattedOneWeekAgo = transformarFecha(fecha)


    try {
      const response = await axios.get(
        t("Recursos.ip") + "/pasos",
        {
          params: {
            email: email.email,
            startTime: formattedOneWeekAgo,
            endTime: formattedCurrentDate,
          },
        }
      );
      console.log("Pasos: ", email.email, response.data);
      //Console.log("Total:", agruparDatosUltimoDia(response.data))
      setPasos_totales(calcularTotalNumeropasosUltimoDia(response.data))

    } catch (error) {
      console.error("Error:", error);
    }
  };

  const consulta_objetivo = async () => {

    try {
      const response = await axios.get(
        t("Recursos.ip") + "/objetivo",
        {
          params: {
            email: email.email,
            parametro: "pasos",
          },
        }
      );
      //Console.log("Objetivo: ", response.data);
      const objetivoaux = response.data;
      setObjetivo(objetivoaux.valor)

    } catch (error) {
      console.error("Error:", error);
    }
  };

  const consulta_distancia = async () => {
    const currentDate = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(currentDate.getDate() - 365);
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    const formattedOneWeekAgo = oneWeekAgo.toISOString().split("T")[0];

    try {
      const response = await axios.get(
        t("Recursos.ip") + "/distancia",
        {
          params: {
            email: email.email,
            startTime: formattedOneWeekAgo,
            endTime: formattedCurrentDate,
          },
        }
      );
      console.log("Distancia: ", response.data);
      setDistancia(response.data[0].metros);
      setFecha(formatearFecha(response.data[0].fecha));


    } catch (error) {
      console.error("Error:", error);
    }
  };

  function calcularTotalNumeropasosUltimoDia(datos) {
    // Encuentra la fecha más reciente en el array
    const fechas = datos.map(item => new Date(item.timestamp));
    const fechaMasReciente = new Date(Math.max(...fechas));

    // Filtra los elementos que tienen la fecha más reciente
    const elementosUltimoDia = datos.filter(item => {
      const itemDate = new Date(item.timestamp);
      return itemDate.toDateString() === fechaMasReciente.toDateString();
    });

    // Calcula el total de numeropasos de los elementos del último día
    const totalNumeropasos = elementosUltimoDia.reduce((sum, item) => sum + item.numeropasos, 0);

    return totalNumeropasos;
  }


  function formatearFecha(dateString) {
    const date = new Date(dateString); // Convertir la cadena a un objeto Date
    if (isNaN(date.getTime())) {
      return "Fecha no válida"; // Manejar el caso en que la cadena no sea una fecha válida
    }

    const dia = date.getDate().toString().padStart(2, "0");
    const mes = (date.getMonth() + 1).toString().padStart(2, "0");
    const anio = date.getFullYear();

    return `${dia}-${mes}-${anio}`;
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: "3%",
          marginTop: "3%",
          color: "#340068",
          marginTop: "5%"
        }}
      >
        <img
          src={Pasos_Icono}
          alt={t("Pasos-Icono")}
          className="imagenSuenoCarta"
        />
        <h3 style={{ color: "black" }}>
          &nbsp;
          {t("Actividad")}
        </h3>
      </div>
      {pasos_totales >= 0 && objetivo && distancia ? (
        <div style={{ height: "80%", display: "flex", flexDirection: "column", alignContent: "center", justifyContent: "center" }}>
          <div>
            <p style={{ color: "black" }}><b>{fecha}</b></p>
          </div>
          <br />
          <div style={{ display: "flex", alignItems: "center", marginTop: "5%", justifyContent: "center" }}>
            <div style={{ marginTop: "-10%", marginBottom: "5%", width: "50%" }}>
              <CircularProgressBarPasos progress={pasos_totales} maxValue={objetivo} texto=" pasos" colortexto="black" colorfondo="#F0F8FF" colorpath="#F8DE22" ancho="80%" alto="80%" />
            </div>
            <div style={{ marginBottom: "17.5%", fontSize: "15 0%", color: "black" }}>

              <span >  <img src={Pasos_Icono_SinRelleno} alt={t("Pasos-Icono") } className="imagenActividadCarta" /> {" "} {formatearNumeroConPuntoDeMiles(pasos_totales)} {t("Pasos")}</span>
              <div style={{ marginTop: "10%" }}>
                <span >  <img src={Distancia_Gris} alt={t("Distancia-Icono")} className="imagenActividadCarta" /> {(distancia !== null ? (distancia / 1000).toLocaleString("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "")} km</span>
              </div>
            </div>
          </div>
        </div>
      ) : (

        <div style={{ color: "black", marginTop: "20%" }}><b>{t("Conectando-BD")}</b></div>
      )}
    </div>
  );
}
export default ActividadCarta;
