import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import "./TituloyFlecha.css"; // Importa el archivo CSS
const TituloyFlecha = ({ title, link = "atras" }) => {
  const navigate = useNavigate();
  const retroceder = () => {
    navigate(-1);
  };
  return (
    <div
      className="align-top-centerMiPulsera"
      style={{
        marginTop: window.innerWidth <= 768 ? "5%" : "2%",
        marginLeft: "-2%",
        marginBottom: "1%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <table>
        <tbody>
          <tr>
            <td style={{ textAlign: "center" }}>
              {link === "atras" ? (
                <BsFillArrowLeftCircleFill
                  onClick={retroceder}
                  style={{
                    fontSize: "2.25rem",
                    color: "#518ea6",
                    marginRight: "30px",
                    margin: "0 auto",
                    marginTop: "-8px",
                    cursor: "pointer",
                  }}
                />
              ) : (
                <Link to={link}>
                  <BsFillArrowLeftCircleFill
                    style={{
                      fontSize: "2.25rem",
                      color: "#518ea6",
                      marginRight: "30px",
                      margin: "0 auto",
                      marginTop: "-8px",
                    }}
                  />
                </Link>
              )}
            </td>
            <td style={{ textAlign: "center" }}>
              {title.split("\n").map((line, lineIndex) => (
                <h1 key={lineIndex}>
                  &nbsp;{line} &nbsp;
                </h1>
              ))}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TituloyFlecha;
