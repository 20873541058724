import React, { useEffect, useContext, useState } from "react";
import UserContext from "../UserContext";
import axios from "axios";
import Card from "../components/Card";
import { Link } from "react-router-dom";
import "./Home.css";
import MisDatosSaludCarta from "../components/RellenoCartas/MisDatosSaludCarta";
import MiDiarioSemanalCarta from "../components/RellenoCartas/MiDiarioSemanalCarta";
import MiAutocuidadoCarta from "../components/RellenoCartas/MiAutocuidadoCarta";
import RecomendacionesCarta from "../components/RellenoCartas/RecomendacionesCarta";
import CartaGrandeHome from "../components/RellenoCartas/CartaGrandeHome";
import { useTranslation } from "react-i18next";

const Home = () => {
  const bearer = useContext(UserContext);
  const { mostrar, setMostrar } = useContext(UserContext);
  const email = useContext(UserContext);
  const { nombre, setNombre } = useContext(UserContext);
  const [sexo, setSexo] = useState("@");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [t] = useTranslation("global");

  const [una, setUna] = useState(false);

  useEffect(() => {
    if (email.email && !una) {
      setUna(true);
      getUserInfo();
    }
  }, [email]); //

  const getUserInfo = async () => {
    try {
      const response = await axios.get(t("Recursos.ip") + "/accounts/getuser", {
        params: {
          email: email.email,
        },
      });

      console.log(response.data);
      setNombre(response.data.nombre);
      setSexo(response.data.sexo);
      console.log(
        "Nombre:",
        email.email,
        response.data.nombre,
        response.data.sexo
      );

      const lastLoginTime = new Date(response.data.ultimologin).getTime();
      const currentTime = new Date().getTime();
      const threeMinutesInMilliseconds = 3 * 60 * 1000; // 3 minutos en milisegundos
      console.log(currentTime - lastLoginTime)
      console.log(3 * 60 * 1000)

      // Comprueba si la fecha de última conexión es más antigua que 3 minutos
      if (currentTime - lastLoginTime > threeMinutesInMilliseconds) {
        enviaralaBD();
      }


    //return { email, name };
  } catch (error) {
    console.error("Error al obtener información del usuario:", error);
    return null;
  }
};

useEffect(() => {
  setMostrar(true);
  // Escucha el evento de cambio de tamaño de la ventana y actualiza el ancho de la ventana
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  window.addEventListener("resize", handleResize);

  return () => {
    // Limpia el evento de cambio de tamaño al desmontar el componente
    window.removeEventListener("resize", handleResize);
  };
}, []);

// function calloauth() {
//   //Console.log(localStorage.getItem("accessToken"));
// }

const enviaralaBD = async () => {
  try {
    const response = await axios.post(
      t("Recursos.ip") + "/accounts/authenticate",
      {
        firebaseAccessTokenJSON: bearer.bearer,
        email: email.email,
      }
    );

    console.log(response.data);

    //enviar al WebView (app movil) el email del usuario
    window.AndroidInterface.sendEmailToAndroid(email.email);
  } catch (error) {
    console.error("Error:", error);
  }
};

// const downloadCSV = async () => {
//   try {
//     const response = await axios.get(
//       t("Recursos.ip") + "/pulsaciones/descargarPulsaciones",
//       {
//         params: { email: email },
//         responseType: "blob", // Esto es importante para obtener una respuesta binaria (blob)
//       }
//     );

//     // Crea una URL de objeto a partir de la respuesta
//     const blob = new Blob([response.data], { type: "text/csv" });
//     const url = window.URL.createObjectURL(blob);

//     // Crea un enlace de descarga y simula un clic en él para iniciar la descarga
//     const a = document.createElement("a");
//     a.href = url;
//     a.download = "archivo.csv"; // Puedes establecer el nombre del archivo aquí
//     a.style.display = "none";
//     document.body.appendChild(a);
//     a.click();

//     // Libera la URL de objeto cuando ya no se necesita
//     window.URL.revokeObjectURL(url);
//   } catch (error) {
//     console.error("Error al descargar el archivo", error);
//   }
// };

const genero = () => {
  switch (sexo) {
    case "Masculino":
      return t("Bienvenido");
    case "Femenino":
      return t("Bienvenida");
    default:
      // Devuelve una imagen predeterminada o maneja el caso no especificado según tus necesidades
      return t("Hola");
  }
};
return (
  <div className="mainContainerHome">

    <div className="margentitulohome">
      {nombre && sexo && (
        <h1>
          {genero()} {nombre}
        </h1>
      )}
    </div>
    <div className="center-tableHome">
      {windowWidth > 1050 ? (
        <>
          <div style={{ height: "100%" }}>
            <Card
              content={
                <CartaGrandeHome
                  progresosueno={8}
                  maxValorsueno={10}
                  progresoactividad={3254}
                  maxValoractividad={4000}
                />
              }
              alto="55vh"
              ancho="45vh"
              className="cardhomegrande"
            />
          </div>

          <div className="card-table">
            <table style={{ margin: "0 auto" }}>
              <tr>
                <td>
                  <Link to="/MiSalud">
                    <Card
                      content={<MisDatosSaludCarta />}
                      alto="25vh"
                      ancho="45vh"
                      className="cardhome"
                    />
                  </Link>
                </td>
                <td>
                  <Link to="/Cuestionario/9/intentos">
                    <Card
                      content={<MiDiarioSemanalCarta />}
                      alto="25vh"
                      ancho="45vh"
                      className="cardhome"
                    />
                  </Link>
                </td>
              </tr>
              <tr>
                <td>
                  <Link to="/Mecuido/Recomendados">
                    <Card
                      content={<MiAutocuidadoCarta />}
                      alto="25vh"
                      ancho="45vh"
                      className="cardhome"
                    />
                  </Link>
                </td>
                <td>
                  <Link to="/Tecuido/Recomendados">
                    <Card
                      content={<RecomendacionesCarta />}
                      alto="25vh"
                      ancho="45vh"
                      className="cardhome"
                    />
                  </Link>
                </td>
              </tr>
            </table>
          </div>
        </>
      ) : (
        <div>
          <div>
            <Card
              content={
                <CartaGrandeHome
                  progresosueno={8}
                  maxValorsueno={10}
                  progresoactividad={3254}
                  maxValoractividad={4000}
                />
              }
              alto="100%"
              ancho="95%"
              className="cardhomegrande"
            />
          </div>
          <div>
            <Link to="/MiSalud">
              <Card
                content={<MisDatosSaludCarta />}
                alto="100%"
                ancho="95%"
                className="cardhome"
              />
            </Link>
            <Link to="/Cuestionario/9/intentos">
              <Card
                content={<MiDiarioSemanalCarta />}
                alto="100%"
                ancho="95%"
                className="cardhome"
              />
            </Link>
            <Link to="/Mecuido/Recomendados">
              <Card
                content={<MiAutocuidadoCarta />}
                alto="100%"
                ancho="95%"
                className="cardhome"
              />
            </Link>

            <Link to="/Tecuido/Recomendados">
              <Card
                content={<RecomendacionesCarta />}
                alto="100%"
                ancho="95%"
                className="cardhome"
              />
            </Link>
          </div>
        </div>
      )}
    </div>
    {/* <button onClick={(e) => calloauth()}>OAUTH</button>
      <button onClick={(e) => getUserInfo(bearer.bearer)}>Email</button>
      <button onClick={downloadCSV}>CSV</button>
      <button onClick={postButtonClick}>Enviar Solicitud Post</button> */}
  </div>
);
};

export default Home;
