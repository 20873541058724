/*global google*/
import { useEffect, useState, useContext, useCallback } from "react";
import { FcAndroidOs } from "react-icons/fc";
import UserContext from "../UserContext";
import { useNavigate } from "react-router-dom";
import Logo from "../Images/Logo.png";
import LogoLetra from "../Images/LogoLetra.png";
import { useTranslation } from "react-i18next";
import axios from "axios";
 
import "./login.css";
 
const Login = () => {
  const SCOPES = "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/fitness.activity.read https://www.googleapis.com/auth/fitness.heart_rate.read https://www.googleapis.com/auth/fitness.location.read https://www.googleapis.com/auth/fitness.sleep.read  ";
  const google = window.google = window.google ? window.google : {};
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const {
    setEmail,
    bearer,
    setBearer,
    setIsAdmin,
    setMostrar,
    ventanaAnterior
  } = useContext(UserContext);
 
  const [tokenCLient, setTokenClient] = useState({});
 
 
  // almacena el token y obtiene datos del usuario
  const manageAccessToken = useCallback((userOauth) => {
    if(!userOauth?.access_token) return;
    const access_token = userOauth.access_token;
 
    // manage token en context y localStorage
    setBearer(access_token);
    localStorage.setItem("accessToken", access_token);
    localStorage.setItem("accessTokenTimestamp", Date.now());
 
    // datos del usuario
    axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }).catch((error) => console.error("Error al obtener información del usuario:", error))
      .then((response) => {
        const { email, name } = response.data;
        // set email del usuario
        setEmail(email);
        // localStorage.setItem("email", email);
 
        // token en backend
        // ser IsAdmin
        axios.post(
          t("Recursos.ip") + "/accounts/authenticate",
          {
            firebaseAccessTokenJSON: access_token,
            email: email,
          }
        ).catch((error) => console.log(error))
        .then((response) => {
          // console.log(" (0) ######################## -> ", response);
          if(response?.data){
            setIsAdmin(response.data.isAdmin);
          } else{
            setIsAdmin(false);
          }
          
        });
 
        // email check
        axios.post(t("Recursos.ip") + "/accounts/emailcheck", { email: email })
          .then((emailResponse) => {
            // console.log(" (1) ######################## -> ", emailResponse);
            if (!emailResponse.data) {
              navigate("/FormularioRegistro");
              return Promise.reject();
            }
 
        // info personal check
            return axios.get(t("Recursos.ip") + "/respuesta", {
              params: { email: email, numerocuestionario: 0 }
            });
          })
          .then((infoResponse) => {
            // console.log(" (2) ######################## -> ", infoResponse && !infoResponse.data);
            if (infoResponse && !infoResponse.data) {
              setMostrar(true);
              navigate("/anadirinfopersonal");
              return Promise.reject();
            }
 
        // estado animo check
            return axios.get(t("Recursos.ip") + "/estadoanimo/cubiertohoy", {
              params: { email: email }
            });
          })
          .then((animoResponse) => {
            // console.log(" (3) ######################## -> ", animoResponse);
            if (animoResponse && !animoResponse.data) {
              setMostrar(true);
              navigate("/CuestionarioEstadoAnimo");
              return Promise.reject();
            }
           
            // si todo lo anterior se ha comprobado
            setMostrar(true);
            navigate(ventanaAnterior ?? "/pantalladeCarga");
          })
          .catch((error) => error ?? console.log(error));
      });
 
  }, [t]);
 
  // obtiene sesión de google con el token
  const initGoogleAuth = useCallback((client_id) => {
    if (
      typeof google !== "undefined" &&
      google.accounts &&
      client_id !== null
    ) {
      // Google está cargado y recogemos datos de usuario
      setTokenClient(
        google.accounts.oauth2.initTokenClient({
          client_id: client_id,
          scope: SCOPES,
          callback: (tokenResponse) => {
            manageAccessToken(tokenResponse); // seguir con el token
          },
        })
      );
      google.accounts.id.prompt();
    } else {
      // Google no está definido aún, intenta inicializar nuevamente después de un delay
      setTimeout(initGoogleAuth, 100);
    }
  }, []);
 
  // obtiene la clave de google para poder usar su API
  useEffect(() => {
    axios.get(t("Recursos.ip") + "/cliente")
    .catch((error) => console.log("Error al obtener información del client_id", error))
    .then((response) => {
      initGoogleAuth(response.data);
    });
  }, [t]);
 
 
  function handleClickGoogle() {
    tokenCLient?.requestAccessToken?.();
    setBearer(bearer);
  }
 
  const handleClick = () => {
    window.open("https://play.google.com/store/apps/details?id=com.talionis.appmovilsimuladorweb&pcampaignid=web_share", '_blank');
  };
 
  return (
    <div style={{ height: "100vh", width: "100%", marginBottom: "-5%" }} className="containerImagenIzq">
      <div className="containerLogin">
        <div ></div>
        <div className="containerBotonesLogin">
          <div className="cardBotonesLogin">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={Logo}
                alt={t("Logo")}
                style={{ width: "30%" }}
              />
              <img
                src={LogoLetra}
                alt={t("Logo-Letra")}
                style={{ width: "30%", marginTop: "5%", marginBottom: "5%" }}
              />
            </div>
            <button
              type="button"
              className="btnGoogle"
              onClick={handleClickGoogle}
            >
              {t("Login-Google")}&nbsp;
            </button>
            <p style={{ marginTop: "5%", marginBottom: "1%", maxWidth: "85%" }}>
              <b>{t("Quieres-App")}</b>
            </p>
            <button
              type="button"
              className="button-style-android"
              onClick={handleClick}
            >
              <FcAndroidOs style={{ fontSize: "30px", marginLeft: "-20%" }} />
              &nbsp;{" "}&nbsp;{" "}&nbsp;{" "} {t("Descarga-App")}
            </button>
 
            <div style={{
              display: "flex",
              flexDirection: "row",
              textAlign: "center",
              position: "relative",
              bottom: "-10%",
              marginLeft: "7.5%"
            }}>
              <div onClick={() => navigate("/Ayuda")} style={{ color: "#518ea6", cursor: "pointer" }}>{t("Ayuda")}</div>
              <span style={{ width: "50px" }}></span>
              <div onClick={() => navigate("/QuienesSomos")} style={{ color: "#518ea6", cursor: "pointer" }}>{t("QuienesSomos")}</div>
            </div>
 
          </div>
        </div>
 
      </div>
    </div>
  );
};
 
export default Login;
 