import React, { useEffect, useContext, useState } from "react";
import UserContext from "../UserContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "./PantalladeCarga.css";

const PantalladeCarga = () => {
  const [t] = useTranslation("global");
  const { email, bearer } = useContext(UserContext);
  const [una, setUna] = useState(false);
  const navigate = useNavigate(); // Usamos useNavigate para la navegación

  useEffect(() => {
    if (email && !una) {
      console.log("Email:", email);
      setUna(true);

      axios.post(t("Recursos.ip") + "/accounts/authenticate", {
        firebaseAccessTokenJSON: bearer,
        email: email,
      }).catch((error) => {
        console.error("Error:", error);
        navigate('/');
      }).then((response) => {
        console.log("Acabo:",response.data);
        navigate('/');
      });
    }
  }, [bearer, email, navigate, t, una]);


  return (
    <div className="loading-screen">
      <div className="loader"></div>
      <br></br>
      <h3>Sincronizando datos</h3>
    </div>
  );
};

export default PantalladeCarga;
