import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { Worker } from "@react-pdf-viewer/core";

import global_es from "./translations/es/global.json"
import global_en from "./translations/en/global.json"
import global_gal from "./translations/gal/global.json"
import { createRoot } from "react-dom/client";

i18next.init({
  interpolation: { escapeValue: false },
  lng:"es",
  resources:{
    es:{
      global:global_es
    },
    en:{
      global:global_en
    },
    gal:{
      global:global_gal
    }
  }
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <App />
      </Worker>
    </I18nextProvider>
  </React.StrictMode>
);

// Si deseas eliminar el bloque anterior, puedes hacerlo, ya que estás renderizando App dos veces.
// ReactDOM.render(<App />, root);

// Si deseas medir el rendimiento de tu aplicación, puedes mantener esta línea.
reportWebVitals();