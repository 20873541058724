import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'; // Importa Link desde React Router
import TituloyFlecha from '../../components/TituloyFlecha';

import './usuariosAdmin.css';

const IntentosAdmin = () => {
    const { id } = useParams(); // Obtiene el parámetro de la URL (id del cuidador)
    const { t, i18n } = useTranslation("global");
    const locale = i18n.language;
    const intlDate = new Intl.DateTimeFormat(locale, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    }); 
    const [intentos, setIntentos] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // Estado para mostrar el indicador de carga

    useEffect(() => {
        const fetchIntentos = async () => {
          setIsLoading(true);
            try {
                // Realiza la solicitud GET para obtener los intentos completos
                const response = await axios.get(`${t('Recursos.ip')}/respuesta/intentoscompleto`, {
                    params: {
                        id: id, // Envía el email como parámetro de la solicitud
                    },
                });

                console.log(response.data)

                // Nombres de los formularios según el ID
                const nombresFormularios = [
                    "SocioDemo",
                    "EuroQol 5D-5L",
                    "CarerQol",
                    "Zarit",
                    "Esfuerzo",
                    "OBQ-E",
                    "PEI",
                    "Satisfacción",
                    "Semanal"
                ];

                // Mapea los resultados de los intentos
                const intentosFormularios = [];

                // Iterar sobre cada formulario y sus intentos
                for (let index = 0; index < response.data.length; index++) {

                    console.log("cuestionario " + index)

                    const numeroIntentos = response.data[index];

                    console.log("Numero intentos",numeroIntentos, index)


                    const nombreFormulario = nombresFormularios[index];
                    const formularioId = index >= 7 ? index + 1 : index; // Ajustar el formularioId para omitir 7

                    // Obtener las fechas de los intentos para este formulario
                    const responseFechas = await axios.get(`${t('Recursos.ip')}/respuesta/fechasId`, {
                        params: {
                            numerocuestionario: formularioId,
                            id: id,
                        },
                    });

                    console.log(responseFechas, "Cuestionario " + formularioId, );

                    const fechas = responseFechas.data;

                    for (let i = 0; i < numeroIntentos; i++) {
                        intentosFormularios.push({
                            formularioId: formularioId,
                            nombreFormulario: nombreFormulario,
                            intento: i + 1, // Número de intento (comenzando desde 1)
                            fecha: fechas[i] || 'N/A'
                        });
                    }

                }

                setIntentos(intentosFormularios); // Guarda los datos en el estado local
            } catch (error) {
                console.error('Error al obtener los intentos:', error);
                // Maneja el error de acuerdo a tus necesidades (puedes mostrar un mensaje al usuario, etc.)
            } finally {
                setIsLoading(false);
            }
        };

        fetchIntentos(); // Llama a la función para obtener la información al cargar el componente
    }, [id, t]);

    const handleRevisar = (formularioId, intento) => {
        // Lógica para manejar la acción de "Revisar" aquí
        console.log(`Revisar formulario ${getNombreFormulario(formularioId)} - Intento ${intento}`);
    };

    const getNombreFormulario = (formularioId) => {
      // Función para obtener el nombre del formulario según el ID
      const nombresFormularios = [
        "SocioDemo",
        "EuroQol 5D-5L",
        "CarerQol",
        "Zarit",
        "Esfuerzo",
        "OBQ-E",
        "PEI",
        "Satisfacción",
        "Semanal"
      ];

      const nombreFormulario = nombresFormularios[formularioId] || "Formulario Desconocido"; // Si no hay nombre, asigna "Formulario Desconocido"
      return nombreFormulario;
    };

    const formatDate = (value) => {
      const date = new Date(value);
      if (isNaN(date)) return value;
      return intlDate.format(date);
    };

    return (
        <div className="admin-page">
          <div className="table-containerAdmin">
            <TituloyFlecha title={t("Intentos de Formularios")} link={"/usuariosAdmin"} />
            <p>Lista de formularios y número de intentos:</p>
            { isLoading ?
              <div className="loader"></div>
            :
              <table className='tableAdmin'>
                <thead>
                  <tr>
                    <th>Nombre del Formulario</th>
                    <th>Número de Intento</th>
                    <th>Fecha</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {intentos.map((intentosFormulario, index) => (
                    <tr key={index}>
                      <td>{intentosFormulario.nombreFormulario}</td>
                      <td>{intentosFormulario.intento}</td>
                      <td>{formatDate(intentosFormulario.fecha)}</td>
                      <td>
                        <Link to={`/RevisarAdmin/${id}/${intentosFormulario.formularioId}/${intentosFormulario.intento}`}>
                          <button onClick={() => handleRevisar(intentosFormulario.formularioId, intentosFormulario.intento)}>Revisar</button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
            </table>
            }
          </div>
        </div>
    );
};

export default IntentosAdmin;
