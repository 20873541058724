import React, { useContext } from "react";
import UserContext from "../../UserContext";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PreguntaCerrarSesion = () => {
  const { setBearer, setEmail, setIsAdmin } = useContext(UserContext);
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { mostrar, setMostrar } = useContext(UserContext);
  // Función para manejar el clic en los botones
  const handleClick = (opcion) => {
    if (opcion === "cancelar") {
      // Lógica para "Prefiero hacerlo más tarde"
      navigate("/");
    } else if (opcion === "confirmar") {
      setBearer(null);
      setEmail(null);
      setIsAdmin(null);

      localStorage.removeItem("accessToken"); // Eliminar el token al cerrar sesión
      localStorage.removeItem("accessTokenTimestamp");

      setMostrar(false)
      navigate("/login");
    }
  };

  return (
    <div>
      <div className="container" style={{marginTop: "10%"}}>
        <div className="titulo">
          {t("CerrarSesion.Pregunta")}
        </div>
        <div className="botones">
          <button className="botoncirc" onClick={() => handleClick("cancelar")}>
          {t("CerrarSesion.Cancelar")}
          </button>
          <button
            className="botoncirc"
            style={{ marginLeft: "5%" }}
            onClick={() => handleClick("confirmar")}
          >
             {t("CerrarSesion.Confirmar")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PreguntaCerrarSesion;
