import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import UserContext from '../../UserContext';
import axios from 'axios';

import "./LoginAdmin.css";

const LoginAdmin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { email, setIsAdmin } = useContext(UserContext);
  const [t] = useTranslation("global");

  const  handleLogin = async (e) => {
    e.preventDefault();

    const response = await axios.post(t("Recursos.ip") + "/accounts/authenticateAdmin", {
        email: email,
        user: username,
        password: password,
      },
    );

    if (response.data === true) {
      setIsAdmin(true);
      navigate('/admin');
    } else {
      alert('Credenciales incorrectas');
    }
  };

  return (
    <div className="LoginAdmin">
      <h2>Login Admin</h2>
      <form onSubmit={handleLogin}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input type="submit" value={"Login"} />
      </form>
    </div>
  );
};

export default LoginAdmin;
