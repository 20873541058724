import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const BorrarUsuarioModal = ({ userSelected, setUserSelected, showModal, setShowModal }) => {
  const { t } = useTranslation("global");
  const [modalInput, setModalInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
    
  const handleDelete = (id) => {
    setIsLoading(true);
    axios.delete(`${t("Recursos.ip")}/accounts/${id}`)
      .catch((error) => console.error("Error al eliminar el usuario:", error))
      .then((response) => {
        setShowModal(false);
        setUserSelected(null);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={"modal-container" + (showModal ? " active" : "")}>
      <div className="modal-window">
        {isLoading ?
          <div className="spinner"></div>
        :
          <>
          <div className="modal-content">
            <h4>Escribe el correo del usuario <strong>{userSelected?.login}</strong> si deseas eliminarlo:</h4>
            <input
              type="text"
              placeholder="Correo"
              className={modalInput !== userSelected?.login? "incorrect": ""}
              value={modalInput}
              onChange={(e) => setModalInput(e.target.value)}
            />
          </div>
          <div className="modal-buttons">
            <button className="modal-cancel"
              onClick={() => setShowModal(false)}
              disabled={isLoading}
            >Cancelar</button>
            <button
              className="modal-delete"
              onClick={() => handleDelete(userSelected?.id)}
              disabled={modalInput !== userSelected?.login || isLoading}
            >Eliminar</button>
          </div>
          </>
        }
      </div>
    </div>
  );
};

export default BorrarUsuarioModal;