import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import TituloyFlecha from '../../components/TituloyFlecha';
import { BsSend, BsFillSendCheckFill, BsX, BsStop, BsStopFill  } from "react-icons/bs";
import "./ConsultaAdmin.css";
import InputExpand from './InputExpand';
import OpenAI from "openai";
import axios from "axios";

const ConsultaAdmin = () => {
  const { t, i18n } = useTranslation("global");
  const locale = i18n.language;
  const intlNumber = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
    useGrouping: true,
  });
  const intlDate = new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  const intlDateTime = new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  });
  

  const [query, setQuery] = useState("");
  const [lastQuery, setLastQuery] = useState("");

  const [sql, setSql] = useState("");
  const [isSqlLoading, setIsSqlLoading] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(false);

  const [thread, setThread] = useState(null);
  const [run, setRun] = useState(null);
  const openAI = new OpenAI({
    apiKey: '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',
    dangerouslyAllowBrowser: 'true',
    organization: "org-xDurmqQ3Jve0uwp5xmx0EPvO",
    project: 'proj_nHssXXNNfZh6s6snM0ysLcjr'
  });


  const [errorMessage, setErrorMessage] = useState("");
  const [abortController, setAbortController] = useState(new AbortController());


  const formatNumber = (value) => {
    const numberValue = Number(value);
    if (isNaN(numberValue)) return value;
    return intlNumber.format(numberValue);
  };

  const formatDate = (value) => {
    if (/^\d{4}-\d{2}-\d{2}$/.test(value)) value += "T00:00:00";
    const date = new Date(value);
    if (isNaN(date)) return value;

    if(date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0)
      return intlDate.format(date);
    else return intlDateTime.format(date);
  };

  const transformData = (data) => {
    return data.map((row) => {
      const transformedRow = {...row};
      for (const key in transformedRow) {
        if (transformedRow[key] === null) transformedRow[key] = "N/A";
        else if (typeof transformedRow[key] === "number")
          transformedRow[key] = formatNumber(transformedRow[key]);
        else if (typeof transformedRow[key] === 'string' && !isNaN(Number(transformedRow[key])))
          transformedRow[key] = formatNumber(transformedRow[key]);
        else if (typeof transformedRow[key] === "string" && !isNaN(new Date(transformedRow[key])))
          transformedRow[key] = formatDate(transformedRow[key]);
        else if (typeof transformedRow[key] === "boolean")
          transformedRow[key] = transformedRow[key] ? t("Si") : t("No");
      }
      return transformedRow;
    });
  };

  const createErrorMessage = (error) => {
    if (error === "Request canceled") setErrorMessage("Consulta cancelada.");
    else setErrorMessage("La consulta generada no es válida:\n" + (error?.response?.data || "Error conectandose a la base de datos."));
    setTimeout(() => {
      setErrorMessage("");
    }, 20100);
  };

  const fetchData = (sql) => {
    const newAbortController = new AbortController();
    setAbortController(newAbortController);

    axios.get(t("Recursos.ia"), {
      params: {
        query: sql
      },
      signal: newAbortController.signal
    }).catch((error) => {
      if (axios.isCancel(error)) {
        createErrorMessage("Request canceled");
      } else
        createErrorMessage(error);
      setTableData([]);
      setTableColumns([]);
      setIsTableLoading(false);
    }).then((response) => {
      if (response?.data) {
        setTableColumns(Object.keys(response.data[0]));
        setTableData(transformData(response.data));
        setIsTableLoading(false);
      } else {
        setTableData([]);
        setTableColumns([]);
        setIsTableLoading(false);
      }
    });
  };

  const fetchQuery = async (query) => {
    try {
      let currentThread = null;
      if (!thread){
        currentThread = await openAI.beta.threads.create();
        setThread(currentThread);
      } else {
        currentThread = thread;
      }

      await openAI.beta.threads.messages.create(
        currentThread.id,
        {
          role: "user",
          content: query,
        }
      );
  
      
      const run = await openAI.beta.threads.runs.create(
        currentThread.id, {
          assistant_id: "asst_d3JmQ2HBBsqGFVvXcXEd3S14",
      });
      setRun(run);

      const runResult = await openAI.beta.threads.runs.poll(
        currentThread.id,
        run.id
      );
  
      if (runResult.status === 'completed') {
        const messages = await openAI.beta.threads.messages.list(
          runResult.thread_id
        );
        for (const message of messages.data.reverse()) {
          if(message.role === "assistant" && message.content[0].text.value) {
            const messageText = message.content[0].text.value;
            const messageSql = messageText.includes("```sql") ? 
              messageText.match(/```sql\s([\s\S]*?)```/g)[0].slice(6, -3).trim()
              : messageText;
            setSql(messageSql);
            setIsSqlLoading(false);
          }
        }
      } else if (runResult.status === 'cancelled' || runResult.status === 'cancelling') {
        console.log("Run cancelled");
        setSql(null);
        setIsSqlLoading(false);
      } else {
        console.log(runResult);
        setSql(null);
        setIsSqlLoading(false);
      }
    } catch (error) {
      console.error(error);
      setSql(null);
      setIsSqlLoading(false);
    }
  };


  const onSubmit = () => {
    // console.log("Query:", query.trim(), lastQuery.trim());
    if (!query?.trim()) return;
    if (!sql || sql === "" || query !== lastQuery) { // fetch sql
      setLastQuery(query);
      setIsSqlLoading(true);
      setTableData([]);
      setTimeout( async () => {
        fetchQuery(query);
      }, 200);
    } else if (sql && sql !== "") { // fetch table
      setIsTableLoading(true);
      setTimeout(() => {
        fetchData(sql);
      }, 5000);
    }
  };

  const onCancel = async () => {
    if (isSqlLoading) {
      console.log("Canceling sql...", run);
      try {
        await openAI.beta.threads.runs.cancel(
          run.thread_id,
          run.id
        );
        setIsSqlLoading(false);
      } catch (error) {
        console.error(error);
      }
    } else if (isTableLoading) {
      console.log("Canceling data...");
      abortController.abort();
    }
  };

  return (
    <div className="ConsultaAdmin">
      {/* <div className="historic">
        <div className="hist-item"> Dame la lista de pulsaciones de cada usuario de cada uno agrupados por dia </div>
      </div> */}
      <div className="ConsultaAdmin-wrapper">
        <TituloyFlecha title={t("Consulta")} link={"/Admin"} />
        <div className="prompt-wrapper">
          <div className="input-wrapper">
            <InputExpand
              text={query}
              setText={setQuery}
              onEnterPress={onSubmit}
              placeholder={t("Consulta")}
              className="input"
              readOnly={isSqlLoading || isTableLoading}
            />
          </div>
          <button
            className={"button" + (query?.trim() ? " active" : "") + (query === lastQuery && sql && !isSqlLoading ? " submit" : "")}
            onClick={ !isSqlLoading && !isTableLoading? onSubmit : onCancel }
          >
            {query === lastQuery && sql && !isSqlLoading ?
              isTableLoading ?
                <BsStopFill color="white"/> /* table loading */
              :
                <BsFillSendCheckFill color="white" /> /* sql ready */
            :
              isSqlLoading ?
                <BsStop color="#518ea6" /> /* sql loading */
              :
                <BsSend color={query.trim() ? "#518ea6" : "#518ea662"} /> /* query ready / default  */
            }
          </button>
        </div>

        {isSqlLoading || sql ?
          <div className="prompt-preview fade-in">
            {isSqlLoading ?
              <div className="content-loader">
                <div className="placeholder-text" style={{ width: "15ch", height: "1rem" }} />
                <div className="placeholder-text" style={{ width: "30ch", height: "1rem" }} />
                <div className="br" />
                <div className="tab" />
                <div className="placeholder-text" style={{ width: "30ch", height: "1rem" }} />
                <div className="placeholder-text" style={{ width: "30ch", height: "1rem" }} />
                <div className="br" />
                <div className="tab" />
                <div className="placeholder-text" style={{ width: "17ch", height: "1rem" }} />
                <div className="placeholder-text" style={{ width: "10ch", height: "1rem" }} />
                <div className="placeholder-text" style={{ width: "20ch", height: "1rem" }} />
                <div className="br" />
                <div className="placeholder-text" style={{ width: "10ch", height: "1rem" }} />
              </div>
              :
              sql ?
                <div className="prompt-sql">{sql}</div>
                : null
            }
          </div>
          : null
        }

        {isTableLoading || tableData.length > 0 ?
          <div className="prompt-result fade-in">
            {isTableLoading ?
              <div className="loader"></div>
              :
              <table className="tableAdmin">
                <thead>
                  <tr>
                    {tableColumns.map((header) => (
                      <th key={header}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((row, i) => (
                    <tr key={row.id || i}>
                      {tableColumns.map((column, j) => (
                        <td key={column || j}>{row[column]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            }
          </div>
          : null
        }

        { errorMessage ?
          <div className="error-message">
            <div>{errorMessage}</div>
            <BsX
              onClick={() => setErrorMessage("")}
              className="close-icon"
            />
          </div>
        : <></>
        }
      </div>
    </div>
  );
};

export default ConsultaAdmin;