import { useEffect, useState, useRef } from "react";
import { BsTrash3 } from "react-icons/bs";
import axios from "axios";
import { useTranslation } from "react-i18next";

import "./EtiquetaUsuarioAdmin.css";

const EtiquetaUsuarioAdmin = ({tags, setTags, user}) => {
  const { t } = useTranslation("global");
  const [selectedTags, setSelectedTags] = useState(
    user?.etiquetas?.map((tag) => ({ value: tag.id, label: tag.nombre })) ?? []
  );
  const [isVisible, setIsVisible] = useState(false);
  const [newTag, setNewTag] = useState("");
  const dropdownRef = useRef(null);

  const [tagToRemove, setTagToRemove] = useState(null);

  const updateUserTags = (newTags) => {
    axios.post(`${t("Recursos.ip")}/etiqueta/addCuidador`, {
      email: user.login,
      etiquetas: newTags.map((tag) => tag.value)
    }).catch((error) => console.error("Error updating tag for user " + user.id, error))
    .then((response) => {});
  };
  const handleSelect = (value) => {
    if (!selectedTags.some((tags) => tags.value === value)) {
      const selectedTag = tags.find((tags) => tags.value === value);
      updateUserTags([...selectedTags, selectedTag]);
      setSelectedTags((selectedTags) => [...selectedTags, selectedTag]);
    }
  };
  const handleRemove = (value) => {
    updateUserTags(selectedTags.filter((tags) => tags.value !== value));
    setSelectedTags((selectedTags) => selectedTags.filter((tags) => tags.value !== value));
  };

  const handleStartDeleteTag = (e, tag) => {
    e.stopPropagation();
    setTagToRemove(tag);
  };
  const handleDeleteTag = (e, tag) => {
    setTagToRemove(null);
    setTags(tags.filter((t) => t !== tag));
  };

  const handleToggleSelect = () => {
    setIsVisible((isVisible) => !isVisible);
  };
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsVisible(false);
        setNewTag("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (newTag.trim() !== "") {
        const newTagObject = { value: newTag.toLowerCase(), label: newTag };
        setTags([...tags, newTagObject]);
        setNewTag(""); // Clear the input field
      }
    }
  };

  return (
  <div
    ref={dropdownRef}
    className="custom-tag-select-container"
  >
    <div className="custom-select-input" onClick={handleToggleSelect}>
      {selectedTags.map((tag) => (
        <div key={tag.value} className="user-selected-tag">
          {tag.label}
          <button
            className="user-selected-tag-button"
            onClick={(e) => {
              e.stopPropagation();
              handleRemove(tag.value);
            }}
          >
            x
          </button>
        </div>
      ))}
      {selectedTags.length === 0 && (
        <input
          type="text"
          placeholder="Etiquetas..."
          className="select-input"
          readOnly
        />
      )}
    </div>

    {isVisible && (
      <div className="dropdown">
        { tagToRemove && (
          <div className="delete-tag-modal-container">
            <div className="delete-tag-modal-window">
              <div className="delete-tag-modal-content">
                <h4>¿Seguro que quieres eliminar la etiqueta {tagToRemove.label}?</h4>
              </div>
              <div className="modal-buttons">
                <button className="modal-cancel"
                  onClick={() => setTagToRemove(null)}
                >Cancelar</button>
                <button
                  className="modal-delete"
                  onClick={(e) => handleDeleteTag(e, tagToRemove)}
                >Eliminar</button>
              </div>
            </div>
        </div>
        )}
        <div className="dropdown-list">
          {tags
            .filter((tag) => !selectedTags.some((selected) => selected.value === tag.value))
            .map((tag) => (
              <div
                key={tag.value}
                className="dropdown-item"
                onClick={() => handleSelect(tag.value)}
              >
                <span
                  onClick={(e) => handleStartDeleteTag(e, tag)}
                  className="dropdown-item-delete"
                ><BsTrash3 /></span>
                <span className="dropdown-item-label">{tag.label}</span>
              </div>
            ))}
          </div>
          <input 
            type="text"
            placeholder="Nueva etiqueta..."
            className="new-tag-input"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            onKeyDown={handleKeyDown}
          />
      </div>
    )}
  </div>
  );
};

export default EtiquetaUsuarioAdmin;